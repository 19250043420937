<template>
<div>
  <my-event-dialog></my-event-dialog>
</div>
</template>

<script>
import EventDialog from '../components/EventDialog'

export default {
  name: 'Eventdialog',
  components: {
    'my-event-dialog': EventDialog
  },
  data () {
    return {
      showError: true
    }
  }
}
</script>

<style scoped>

</style>
